export const messages = {
  en: {
    global: {
      yes: 'Yes',
      no: 'No',
      back: 'Back',
      сancel: 'Cancel',
      сancel2: 'Cancel',
      confirm: 'Confirm',
      apply: 'Apply',
      add: 'Add',
      from: 'out of',
      save: 'Save',
      send: 'Send',
      somethingWrong: 'Something went wrong...',
      notSelected: 'Not selected',
      ok: 'ok',
      nothingFound: 'Nothing found',
      error: 'Error',
      all: 'All',
      clear: 'Clear',
      apply2: 'Apply',
      drop: 'Reset',
      currency: ' $',
      enter: 'enter count',
    },
    message: {
      OrdersInPipeline: 'There are orders in this pipeline.',
      tasks: 'No tasks in progress',
      notInst: 'GoSklad is not installed',
    },
    countries: {
      Abkhazia: 'Abkhazia',
      Australia: 'Australia',
      Austria: 'Austria',
      Azerbaijan: 'Azerbaijan',
      'Aland Islands': 'Aland Islands',
      Albania: 'Albania',
      Algeria: 'Algeria',
      Anguilla: 'Anguilla',
      Angola: 'Angola',
      Andorra: 'Andorra',
      Argentina: 'Argentina',
      Armenia: 'Armenia',
      Aruba: 'Aruba',
      Afghanistan: 'Afghanistan',
      Bahamas: 'Bahamas',
      Bangladesh: 'Bangladesh',
      Barbados: 'Barbados',
      Bahrain: 'Bahrain',
      Belarus: 'Belarus',
      Belize: 'Belize',
      Belgium: 'Belgium',
      Benin: 'Benin',
      Bulgaria: 'Bulgaria',
      Bolivia: 'Bolivia',
      'Bosnia & Herzegovina': 'Bosnia & Herzegovina',
      Botswana: 'Botswana',
      Brazil: 'Brazil',
      'Brunei Darussalam': 'Brunei Darussalam',
      Burundi: 'Burundi',
      Bhutan: 'Bhutan',
      'Vatican City': 'Vatican City',
      'United Kingdom': 'United Kingdom',
      Hungary: 'Hungary',
      Venezuela: 'Venezuela',
      'Timor, East': 'Timor, East',
      'Viet Nam': 'Viet Nam',
      Gabon: 'Gabon',
      Haiti: 'Haiti',
      Gambia: 'Gambia',
      Ghana: 'Ghana',
      Guadeloupe: 'Guadeloupe',
      Guatemala: 'Guatemala',
      Guinea: 'Guinea',
      'Guinea-Bissau': 'Guinea-Bissau',
      Germany: 'Germany',
      Gibraltar: 'Gibraltar',
      'Hong Kong': 'Hong Kong',
      Honduras: 'Honduras',
      Grenada: 'Grenada',
      Greenland: 'Greenland',
      Greece: 'Greece',
      Georgia: 'Georgia',
      Guam: 'Guam',
      Denmark: 'Denmark',
      Dominica: 'Dominica',
      'Dominican Republic': 'Dominican Republic',
      Egypt: 'Egypt',
      Zambia: 'Zambia',
      'Western Sahara': 'Western Sahara',
      Zimbabwe: 'Zimbabwe',
      Israel: 'Israel',
      India: 'India',
      Indonesia: 'Indonesia',
      Jordan: 'Jordan',
      Iraq: 'Iraq',
      Iran: 'Iran',
      Ireland: 'Ireland',
      Iceland: 'Iceland',
      Spain: 'Spain',
      Italy: 'Italy',
      Yemen: 'Yemen',
      Kazakhstan: 'Kazakhstan',
      Cambodia: 'Cambodia',
      Cameroon: 'Cameroon',
      Canada: 'Canada',
      Qatar: 'Qatar',
      Kenya: 'Kenya',
      Cyprus: 'Cyprus',
      Kyrgyzstan: 'Kyrgyzstan',
      Kiribati: 'Kiribati',
      China: 'China',
      Colombia: 'Colombia',
      'Korea, DPR': 'Korea, D.P.R.',
      Korea: 'Korea',
      'Costa Rica': 'Costa Rica',
      'Cote dIvoire': "Cote d'Ivoire",
      Cuba: 'Cuba',
      Kuwait: 'Kuwait',
      'Lao PDR': 'Lao P.D.R.',
      Latvia: 'Latvia',
      Lesotho: 'Lesotho',
      Liberia: 'Liberia',
      Lebanon: 'Lebanon',
      'Libyan Arab Jamahiriya': 'Libyan Arab Jamahiriya',
      Lithuania: 'Lithuania',
      Liechtenstein: 'Liechtenstein',
      Luxembourg: 'Luxembourg',
      Mauritius: 'Mauritius',
      Mauritania: 'Mauritania',
      Madagascar: 'Madagascar',
      Macedonia: 'Macedonia',
      Malawi: 'Malawi',
      Malaysia: 'Malaysia',
      Mali: 'Mali',
      Maldives: 'Maldives',
      Malta: 'Malta',
      Morocco: 'Morocco',
      Mexico: 'Mexico',
      Mozambique: 'Mozambique',
      Moldova: 'Moldova',
      Monaco: 'Monaco',
      Mongolia: 'Mongolia',
      Namibia: 'Namibia',
      Nepal: 'Nepal',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Netherlands: 'Netherlands',
      Nicaragua: 'Nicaragua',
      'New Zealand': 'New Zealand',
      Norway: 'Norway',
      'United Arab Emirates': 'United Arab Emirates',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Panama: 'Panama',
      Paraguay: 'Paraguay',
      Peru: 'Peru',
      Poland: 'Poland',
      Portugal: 'Portugal',
      Russia: 'Russia',
      Romania: 'Romania',
      'San Marino': 'San Marino',
      'Saudi Arabia': 'Saudi Arabia',
      Senegal: 'Senegal',
      Serbia: 'Serbia',
      Singapore: 'Singapore',
      'Syrian Arab Republic': 'Syrian Arab Republic',
      Slovakia: 'Slovakia',
      Slovenia: 'Slovenia',
      Somalia: 'Somalia',
      Sudan: 'Sudan',
      USA: 'USA',
      Tajikistan: 'Tajikistan',
      Thailand: 'Thailand',
      Tanzania: 'Tanzania',
      Togo: 'Togo',
      Tunisia: 'Tunisia',
      Turkmenistan: 'Turkmenistan',
      Turkey: 'Turkey',
      Uganda: 'Uganda',
      Uzbekistan: 'Uzbekistan',
      Ukraine: 'Ukraine',
      Uruguay: 'Uruguay',
      Micronesia: 'Micronesia',
      Fiji: 'Fiji',
      Philippines: 'Philippines',
      Finland: 'Finland',
      France: 'France',
      Croatia: 'Croatia',
      Chad: 'Chad',
      Montenegro: 'Montenegro',
      'Czech Republic': 'Czech Republic',
      Chile: 'Chile',
      Switzerland: 'Switzerland',
      Sweden: 'Sweden',
      'Sri Lanka': 'Sri Lanka',
      Ecuador: 'Ecuador',
      Eritrea: 'Eritrea',
      Estonia: 'Estonia',
      Ethiopia: 'Ethiopia',
      'South Africa': 'South Africa',
      Jamaica: 'Jamaica',
      Japan: 'Japan',
    },
    NavBar: {
      orders: 'Orders',
      documents: 'Documents',
      remnants: 'In stock',
      analytics: 'Analytics',
      archive: 'Archive',
      clients: 'Customers',
      deals: 'Deals',
      objects: 'Facilities',
    },
    HomeMenu: {
      selectLengPlaceholder: 'Language selection',
      err: 'Error',
      tasks: {
        notask: 'No tasks in progress',
        title: 'Task list',
      },
      import: {
        title: 'Import of goods',
        btnPlaceholder: 'Upload a file',
      },
      sync: {
        title: 'Syncing products',
        amo_go: 'Synchronization Kommo products -> GoSklad',
        go_amo: 'Synchronization products GoSklad -> Kommo',
        oneC: 'Synchronization remains with 1C',
      },
      settings: {
        title: 'Settings',
        general: 'General settings',
        businessProcesses: 'Business-processes',
        entities: 'Entities settings',
        docs: 'Documents',
        account: 'Account settings',
        integration: 'Integrations',
        archive: 'Archive',
        categories: 'Product categories',
        properties: 'Product properties',
        sync: 'Product synchronization settings',
        clients: 'Customer field settings',
      },
      user: { title: 'User settings' },
    },
    DelBtnAccept: {
      del: 'Delete',
      confirmDel: 'Are you sure you want to delete it?',
    },
    HomeMenuUser: {
      logout: 'Log out',
    },
    AccountSettings: {
      header: 'Account settings',
      general: {
        header: 'General',
        accountName: 'Account name',
        accountCountry: 'Account country',
        steps: 'Stages of products reservation from the warehouse',
        divide: 'Separate rights',
      },
      users: {
        header: 'Users',
        addBtn: 'Add a user',
        table: {
          name: 'Name',
          email: 'Email',
          phone: 'Phone',
          status: 'Status',
          statusActive: 'Enabled',
          statusUnActive: 'Disabled',
          editBtn: 'Edit',
          display: 'Show',
        },
      },
      license: {
        header: 'License',
      },
      editUser: {
        header: 'Editing a user',
        activity: 'Activity',
        editing_remains: 'Editing product',
        change_remains: 'Arrival / Write-off / Movement of goods',
        admin: 'Admin',
      },
      newUser: {
        header: 'Adding a user',
        name: 'Name',
        email: 'Email',
        alreadyExists: 'Such a user already exists.',
        addExistUser: 'Add his to your account?',
        notificationHeadSuccess: '{name} invited to the account',
        notificationHeadFail: '{name} was not invited to the account',
        notificationTextFail: 'Something went wrong...',
      },
    },
    EditUserProfile: {
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      change: 'Edit',
      add: 'Add',
    },
    EditUserChangePassword: {
      currentPassword: 'Current password',
      newPassword: 'New password',
      confirmNewPassword: 'Confirm the new password',
      showPassword: 'Show Password',
      changePassword: 'Changing the password',
    },
    Profile: {
      header: 'Profile',
      profile: 'Profile',
      changePassword: 'Change password',
    },
    ProfileChangeModal: {
      header: ' Changing  {type}',
      codeText: 'Code sent {source} to {login}:',
      inputText: 'Enter {source}',
      notificationTextFail: 'You have used too many code entry attempts',
      notificationTextSuccess: 'Successfully',
      WhatsApp: 'in WhatsApp',
      email: {
        forTitle: 'mail',
        textForField: 'new mail',
      },
      phone: {
        forTitle: 'phone number',
        textForField: 'new phone number',
      },
    },
    EditStuff: {
      header: 'General settings',
      otherDeals: 'Allow adding items from the reserve to other orders',
      zeroCount: 'Allow adding goods with zero balance to order',
      oneWh: 'Products in a order from only one warehouse',
      manualBudjet: 'Allow manual editing of the order sum',
      publicOrder: {
        header: 'Pubic orders',
      },
      stepsRes: {
        header: 'Stages of products reservation from the warehouse',
        text: 'Attention! Within one pipeline, the reserving stage should be before the write-off stage. If the reservation stage is not selected, then the reservation will take place at the moment the item is added to the deal.',
        blockRes: 'Block product changes in reservation stages',
        hopper: 'Pipeline "{name}"',
        placeholder: 'Not selected | Selected: {count} | Selected: {count}',
      },
      stepsWO: {
        header: 'Stages of write-off of goods from the warehouse',
        text: 'Attention! Within one pipeline, the write-off stage must be later than the reservation stage.',
        hopper: 'Pipeline "{name}"',
      },
      dealBinding: {
        header: 'Deal field binding',
        budget: 'Do not complete the order budget',
        field: 'Field  "{name}"',
        number: '(number)',
      },
    },
    SettingEntities: {
      header: 'Entity settings',
      statBtn: 'Statuses',
      tabs: {
        orders: 'Orders',
        company: 'Companies',
        contacts: 'Contacts',
        products: 'Goods',
      },
      table: {
        field: 'Field',
        type: 'Type',
        visible: 'Visibility in the order',
        edit: 'Editing in the order',
        PubOrdVisible: 'Visibility in a public order',
        PubOrdEdit: 'Editing in a public order',
        headerDeal: 'The title of the product in the order',
        double: 'Duplicate to new batches',
        bind: 'Linking to categories',
        btnSaveAll: 'Save all fields',
        titCat: 'Select categories',
      },
      fieldsProp: {
        btnCats: 'Categories',
        selectCat: 'Choosing a subcategory',
      },
      entCats: {
        header: 'Product categories',
        titleAdd: 'Adding a new subcategory',
        titleChangeName: 'Changing the category name',
        titleSave: 'Saves the category name',
        titleChange: 'Returns the original name',
        url: 'Image url',
        del: 'Deleting a category',
        add: 'Adding a new subcategory',
      },
      entStats: {
        header: 'Setting up statuses',
        name: 'Title',
        stat: 'Status',
        titRes: 'Reservation',
        titWO: 'Write-off',
        newStatName: 'Status {count}',
        new: 'new status',
      },
    },
    BusinessProcessesSettings: {
      title: 'Business-processes',
      tags: 'Tags',
      scenarios: 'Scenarios',
      addTag: 'Add',
      nothingFound: 'Nothing was found...',
      showAllProductsToAllCustomers: 'Show all products to all customers',
      showTaggedProductsToCustomersOnlyWithMatchingTags: 'Show tagged products to customers only with matching tags',
      showCustomersProductsWithMatchingTagsOrWithoutTags: 'Show customers products with matching tags/without tags',
      selectedScenario: 'Selected scenario'
    },
    DocumentSetting: {
      templAdd: 'Adding a document template',
      templChange: 'Editing a document template',
      header: 'Document settings',
      googleAuth: 'You are signed in to Google Drive as "{name}".',
      exit: 'Logout',
      enter: 'Login',
      googleNotAuth: 'You are not logged in to the Google Drive service',
      folder: 'Documents folder',
      templFields: 'Template fields',
      templUpdate: 'Update template fields',
      templLink: 'Binding document fields',
      docField: 'The "Documents" field',
      contOrder: 'Order of contact names',
      hooks: 'Hooks',
      hookDown: 'Hook when loading a document into a deal',
      hookGen: 'Hook when generating a document in a deal',
      templDocs: 'Document Templates',
      templDocsText:
        'The template files are available as read-only via the following link. The Google ID file is part of the file link Example link: https://docs.google.com/spreadsheets/d/1XdXdEMtUFa8V__UK234432Dpx5-CeI/editgid\u003d0, where the Google ID file is: 1XdXdEMtUFa8V__UK234432Dpx5-CeI Instead of the Google ID file, it is allowed to enter the full address',
      alert: 'Текст скопирован удачно',
      alert2: 'Текст не скопирован автоматически',
      alert3: 'Попробуйте вручную',
      titles: {
        name: 'Name',
        GID: 'File Google id',
        temp: 'Template',
        typeTemp: 'Template type',
        typeDown: 'Download type',
        POVisible: 'Visibility in public order',
      },
      autoGen: 'Google authorization',
      addNew: {
        name: 'Name:',
        GID: 'File Google id:',
        stepGen: 'Autogeneration stage:',
        url: 'Url of the last document:',
        templType: 'Template type:',
        downType: 'Download type:',
        orient: {
          header: 'Orientation',
          kn: 'Book',
          alb: 'Landscape ',
        },
        is: 'Source',
      },
      fields: {
        header: 'Fields for document templates',
      },
      docRow: {
        templ: 'Template',
      },
    },
    Integrations: {
      header: 'Integrations',
      oneCText:
        'To integrate with 1C-Genesis, you need to install the «Genesis: 1C Integration» widget',
      btnSettings: 'Settings',
      btnInstalled: 'Installed',
      btnInstall: 'Install',
      free: 'Free add-on',
      amoError: 'To install, you must be an amoCRM platform user',
    },
    Archive: {
      count: 'Found: {count}',
      unArchive: 'Unarchive product',
      tabs: {
        products: 'Products / Services',
        companies: 'Companies',
        contacts: 'Contacts',
        orders: 'Orders',
      },
    },
    Genezis: {
      btnSet: 'Integration Settings with 1C-Genesis',
      btnSet2: 'Settings  «Genesis: 1C integration»',
      gActive: 'Genesis activated.',
      disable: 'Deactivate',
      dDisable: 'Genesis is not activated.',
      active: 'Activate',
      fields: {
        comp: 'Binding company fields',
        compName: 'Name',
        compInn: 'Inn',
        compKpp: 'CAT',
        compOgrn: 'PSRN',
        acc: 'Linking account fields',
        accName: 'Name',
        accSch: 'Correspondent account',
        accBik: 'Bic bank',
        accRas: 'Payment account',
      },
      field: 'Field "{name}"',
    },
    Auth: {
      header: 'Authorization',
      tgPh: 'Telegram login (login)',
      passwordPh: 'Password from GoSklad',
      restore: 'Recover password',
      error: 'Invalid username or password',
      btnAuth: 'Log in',
      drop: 'Password reset',
      reg: 'Registration',
      name: 'Name',
      sendCode: 'Sent the code to :',
      notificationTextPassword: 'We have sent you a new password to: {source}',
      notificationTextCode: 'We have sent you a confirmation code to: {source}',
      notificationTextSuccess:
        'You are registered, see the password on: {source}',
      notificationTextTryCodeFail: 'You have used too many code entry attempts',
    },
    Amo: {
      header: 'amoCRM Integration Settings',
      office: 'amoCRM cabinet:',
      tabs: {
        general: 'General Settings',
        sync: 'Product synchronization settings',
        docs: 'Document settings',
      },
      GenSett: {
        stepResH: 'Stages of products reservation from the warehouse',
        stepResT:
          'Attention! Within one pipeline, the reserving stage should be before the write-off stage. If the reservation stage is not selected, then the reservation will take place at the moment the item is added to the deal.',
        block: 'Block product changes in reservation stages',
        hopper: 'Pipeline "{name}"',
        stepWOH: 'Stages of write-off of goods from the warehouse',
        stepWOT:
          'Attention! Within one pipeline, the write-off stage must be later than the reservation stage.',
        linkFH: 'Deal field binding',
        linkFBudjet: 'Do not complete the deal budget',
        field: 'Field  "{name}"',
        number: '(number)',
        leadsDeals: {
          field_products: 'Products',
          field_search_products: 'Product Search field',
          field_cost_price: 'Cost price',
          field_profit: 'Profit',
          field_budget: 'Total budget',
          field_nds_sum: 'VAT amount',
        },
      },
      SyncSett: {
        header: 'Select a list of products from amoCRM to sync',
        hide: 'Hidden regular amoCRM tabs with products. In the standard tab with goods, you can also attach goods to orders, but the quantity of goods on the GoSklad will not be taken into account.',
        corrH:
          'Setting up the correspondences of the fields of amoCRM products and the properties of products on the GoSklad',
        corrT:
          'Specifying the comparison fields is necessary in order to search for duplicates of goods in these fields during the synchronization of the amoCRM and GoSkald lists that were added manually. If no fields are specified, products will be added without searching for duplicates. If you are configuring the binding of product groups from amoCRM and product categories in GoSklad, you need to make sure that the list of groups matches the list of categories.',
        amoName: 'Name of the amoCRM product field',
        goName: 'Name of the product field on GoSklad',
        comparison: {
          header: 'Comparison',
          name: 'by name',
          art: 'by name and article',
          batch: 'by name, article and batch',
        },
        update: 'Update fields',
        goAmo: 'GoSklad -> amoCRM',
        amoGo: 'amoCRM -> GoSklad',
        autoSync: 'Auto-synchronization of goods',
        autoSyncOrder:
          'Auto-synchronization of the list of products in the order',
      },
    },
    kommo: {
      header: 'Kommo Integration Settings',
      office: 'Kommo cabinet:',
      tabs: {
        general: 'General Settings',
        sync: 'Product synchronization settings',
        docs: 'Document settings',
      },
      GenSett: {
        stepResH: 'Stages of products reservation from the warehouse',
        stepResT:
          'Attention! Within one pipeline, the reserving stage should be before the write-off stage. If the reservation stage is not selected, then the reservation will take place at the moment the item is added to the deal.',
        block: 'Block product changes in reservation stages',
        hopper: 'Pipeline "{name}"',
        stepWOH: 'Stages of write-off of goods from the warehouse',
        stepWOT:
          'Attention! Within one pipeline, the write-off stage must be later than the reservation stage.',
        linkFH: 'Deal field binding',
        linkFBudjet: 'Do not complete the deal budget',
        field: 'Field  "{name}"',
        number: '(number)',
        leadsDeals: {
          field_products: 'Products',
          field_search_products: 'Product Search field',
          field_cost_price: 'Cost price',
          field_profit: 'Profit',
          field_budget: 'Total budget',
          field_nds_sum: 'VAT amount',
        },
      },
      SyncSett: {
        header: 'Select a list of products from Kommo to sync',
        hide: 'Hidden regular Kommo tabs with products. In the standard tab with goods, you can also attach goods to orders, but the quantity of goods on the GoSklad will not be taken into account.',
        corrH:
          'Setting up the correspondences of the fields of Kommo products and the properties of products on the GoSklad',
        corrT:
          'Specifying the comparison fields is necessary in order to search for duplicates of goods in these fields during the synchronization of the Kommo and GoSkald lists that were added manually. If no fields are specified, products will be added without searching for duplicates. If you are configuring the binding of product groups from Kommo and product categories in GoSklad, you need to make sure that the list of groups matches the list of categories.',
        amoName: 'Name of the Kommo product field',
        goName: 'Name of the product field on GoSklad',
        comparison: {
          header: 'Comparison',
          name: 'by name',
          art: 'by name and article',
          batch: 'by name, article and batch',
        },
        update: 'Update fields',
        goAmo: 'GoSklad -> Kommo',
        amoGo: 'Kommo -> GoSklad',
        autoSync: 'Auto-synchronization of goods',
        autoSyncOrder:
          'Auto-synchronization of the list of products in the order',
      },
    },
    DocumentsTitles: {
      status_id: {
        name: 'Status',
        placeholder: 'Document status',
      },
      template_name: {
        name: 'Document name',
        placeholder: 'Document name',
      },
      lead_name: {
        name: 'Order name',
        placeholder: 'Order name',
      },
      company_name: {
        name: 'Company',
        placeholder: 'Company',
      },
      contact_name: {
        name: 'Contact',
        placeholder: 'Contact',
      },
      user_name: {
        name: 'Who formed',
        placeholder: 'Who formed',
      },
      created_at: {
        name: 'When formed',
        placeholder: 'When formed',
      },
    },
    Analytics: {
      positions: 'Company positions "{company}"',
      group: 'Group by: ',
      reportTypes: {
        customers: 'Customer report',
        sales: 'Sales report',
        movement: 'Movement of goods',
      },
      titles: {
        customers: {
          company: 'Company',
          contact: 'Contact',
          leads: 'Orders',
          sum: 'Turnover',
          prib: 'Profit',
          otv: 'Responsible',
          poz: 'Positions',
        },
        sales: {
          name: 'Title',
          count: 'Quantity',
          sum: 'Turnover',
          cost_sum: 'Cost price',
          prib: 'Profit',
          leads: 'Transactions',
          poz: 'Positions',
        },
        movement: {
          event_date: 'Event date',
          event_type: 'Event type',
          article_number: 'Article',
          title: 'Title',
          party: 'Batch',
          quantity: 'Quantity',
          cost_price: 'Cost price',
          sum: 'Sum',
          initial_warehouse: 'Initial warehouse',
          final_warehouse: 'Final warehouse',
          user: 'Customers',
          comment: 'Comment',
        },
      },
      groupComp: 'Companies',
      groupContact: 'Contacts',
      general: 'General',
      subTitles: {
        name: 'Title',
        count: 'Quantity',
        sum: 'Turnover',
        prib: 'Profit',
      },
      reportFilters: {
        createTempl: 'Create a template',
        system: 'System',
        fields: {
          product: 'Articles/Names',
          responsible: 'Responsible',
          pipeline: 'Pipelines',
          company: 'Company',
          contact: 'Contacts',
          users: 'Users',
          event_type: 'Event type',
          product_batch: 'Batch',
          initial_warehouse: 'Initial warehouse',
          final_warehouse: 'Final warehouse',
          quantity: 'КоллиQuantityчество',
        },
      },
      createModal: {
        new: 'New report',
        name: 'Report name',
        placeholder: 'By sales',
        by: 'By ',
      },
      reportGrid: {
        show: 'Show',
        hide: 'Hide',
      },
      chartsFilters: {
        type: 'Report type',
        days: 'By days',
        months: 'By month',
        years: 'By year',
        total: 'Total sales',
        customers: 'By clients',
        sales: 'By sales',
      },
      systemsForReports: {
        entities: {
          placeholder: 'Entity',
          trade: 'Deal',
          contact: 'Contact',
          company: 'Company',
        },
        data: {
          placeholder: 'Data',
          event: 'Events',
          data: 'Data',
        },
        trade: {
          placeholder: 'Orders',
          1: 'Creation',
          2: 'Changing the stage',
          3: 'changing the responsible',
          4: 'person adding a note',
          5: 'changing the field',
          6: 'linking the company',
          7: 'linking the contact',
        },
        trade2: {
          placeholder: 'Orders',
          1: 'related contacts',
          2: 'related company',
          3: 'notes',
          4: 'creation',
          5: 'date closing date (successful, unsuccessful stages',
          6: 'tags',
          7: 'task list',
        },
        contact: {
          placeholder: 'Contacts',
          1: 'creation (date)',
          2: 'call',
          3: 'new chat (message) - list of chats: vk, fb, insta, whatsapp, cart, other',
          4: 'sms',
          5: 'sent, letter sent / letter received',
          6: 'change of responsible',
          7: 'person change of field / tag',
          8: 'binding to transaction',
          9: 'binding to contact',
        },
        contact4: {
          placeholder: 'Contacts',
          1: 'responsible',
          2: 'phone',
          3: 'number mail',
          4: 'related transactions',
          5: 'related company',
          6: 'position',
          7: 'user fields (id)',
          8: 'notes',
          9: 'tags',
          10: 'task list',
        },
        company: {
          placeholder: 'Company',
          1: 'binding to a transaction)',
          2: 'binding to a contact',
          3: 'creating (date)',
          4: 'a call',
          5: 'sent a letter / received a letter',
          6: 'changing a field / tag',
        },
        company6: {
          placeholder: 'Company',
          1: 'responsible',
          2: 'phone',
          3: 'number mail',
          4: 'website',
          5: 'related contacts',
          6: 'related transactions',
          7: 'custom fields (id)',
          8: 'tags',
          9: 'notes',
          10: 'task list',
        },
        task: {
          placeholder: 'Tasks',
          1: 'creating a task',
          2: 'changing a task (deadline)',
          3: 'changing a task (type)',
          4: 'changing a task (content)',
          5: 'closing a task (with or without result ???)',
        },
        task8: {
          placeholder: 'Tasks',
          1: 'responsible',
          2: 'date of creation',
          3: 'date of change',
          4: 'date of completion',
          5: 'task type task',
          6: 'content',
          7: 'result of execution',
          8: 'related companies / contacts / transactions',
        },
        date: {
          placeholder: 'Date interval',
        },
        vortexAndPhase: {
          placeholder: 'Pipeline and status',
          all: 'All',
          1: 'Status 1',
          2: 'Status 2',
          3: 'Status 3',
          4: 'Status 4',
          5: 'Pipeline 1',
          6: 'Pipeline 2',
          7: 'Pipeline 3',
          8: 'Pipeline 4',
        },
        responsible: {
          placeholder: 'Responsible',
          1: 'Responsible 1',
          2: 'Responsible 2',
          3: 'Responsible 3',
          4: 'Responsible 4',
          5: 'Responsible 5',
        },
        tags: {
          placeholder: 'Tags',
          1: 'Tag 1',
          2: 'Tag 2',
          3: 'Tag 3',
          4: 'Tag 4',
          5: 'Tag 5',
        },
        initiator: {
          placeholder: 'Event initiator',
          1: 'Initiator 1',
          2: 'Initiator 2',
          3: 'Initiator 3',
          4: 'Initiator 4',
          5: 'Initiator 5',
        },
        before: {
          placeholder: 'Before',
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
        },
        after: {
          placeholder: 'After',
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
        },
        taskStatus: {
          placeholder: 'Task status',
          0: 'Open',
          1: 'Successful',
          2: 'Cancelled',
          3: 'Deleted',
        },
        taskType: {
          placeholder: 'Task type',
          0: 'Open',
          1: 'Successful',
          2: 'Cancelled',
          3: 'Deleted',
        },
        messageType: {
          placeholder: 'Message type',
          1: 'Incoming',
          2: 'Outgoing',
        },
      },
    },
    Clients: {
      tabs: {
        contact: 'Contacts',
        company: 'Сompany',
      },
      exitConfirm: 'Are you sure you want to exit without saving?',
      exit: 'Exit',
      Edit: {
        archivate: 'Archive',
        orders: 'Order history',
        emptyF: 'Empty field',
        invalidEmail: 'Email invalid',
        lenght: 'Minimum of 3 characters',
        link: 'Binding',
        rating: 'Rating',
        follow: 'Follow',
        delete: 'Remove',
      },
    },
    ostatki: {
      inWH: 'Only positive balances',
      newPoz: 'Add',
      syncT: 'Update the list',
      settingsT: 'Table setup',
      allwhs: 'All warehouses',
      changePrice: 'Price editing',
      changePoz: 'Product editing',
      red: 'Editing {name}',
      arch: 'Archive',
      type: 'Type:',
      cat: 'Category:',
      tov: 'Product',
      ysl: 'Service',
      taskH: 'Added a task',
      taskH2: 'Export report to xlsx',
      taskT: 'Exporting a list of products to xlsx',
      pn: 'Price change "{name}"',
      price: 'Price:',
      price2: 'Change the price of this product in open transactions?',
      sortBy: 'Sort by:',
      att: 'Attention! Account data is being downloaded. This may take a few minutes',
      att2: 'Attention, the data of your account of the previous version of the warehouse has been detected. To download the data, click the DOWNLOAD button. The download may take some time.',
      d: 'Download',
      selWh: 'Select a warehouse',
      ysls: 'Services',
      menu: 'Menu',
      btn1: 'Add',
      btn2: 'Write off',
      btn3: 'Moving',
      btn4: 'Archive',
      f: 'Filter',
      spis: 'Write-off of goods',
      art: 'Article',
      name: 'Name',
      batch: 'Batch No.',
      wh: 'Warehouse',
      wh1: 'Warehouse (from where)',
      wh2: 'Warehouse (to where)',
      count: 'Quantity',
      reason: 'Comment',
      per: 'Transfer of goods',
      new: 'New',
      osn: 'Main warehouse',
      allObj: 'All facilities',
      tip: 'Type',
      cat2: 'Category',
      op: 'Description',
      nds1: 'VAT',
      nds2: 'included in the price',
      nds3: '% can be changed',
      nds4: '% VAT',
      fieldE: 'Empty field',
      doubleBatch: 'Existing batch',
      add: 'Adding goods',
      ed: 'Units of measurement',
      seb: 'Cost price',
      price3: 'Price',
      cost_sum: 'Purchase amount',
      sum: 'Amount',
      ndsT: 'VAT',
      nt: 'Setting up a table',
      ex: 'Exporting a table to xlsx',
      error: 'Error',
      total_count: 'Total positions:',
      total_cost_sum: 'Purchase amount:',
      total_sum: 'Sale amount:',
    },
    orders: {
      ors: 'Orders',
      hfs: 'Hide final stages',
      cre: 'Create an order',
      exp: 'Export',
      up: 'Update the list',
      sd: 'Orders:',
      woz: 'Refund',
      ser: 'Search and filter',
      po: 'Public order',
      link: 'Link',
      unlink: 'Unlink',
      lid: 'ID lead',
    },
    tableSet: {
      name: 'Setting up a table',
      sm: 'Drag and drop columns to change the order',
    },
    newOrder: {
      newOrderName: '#{id}',
      a: 'Choosing a template',
      saving: 'Saving ',
      confirmOrder: 'Confirm order',
      spis: 'List of positions',
      dan: 'Order details',
      name: 'Title',
      stat: 'Status',
      soz: 'Generated:',
      izm: 'Changed:',
      sum: 'Order sum:',
      cp: 'Cost price:',
      prib: 'Profit:',
      sotr: 'Employee:',
      auth: 'Log in to Google to work with documents.',
      gen: 'Generation',
      gen2: 'Generate',
      z: 'Downloading',
      z2: 'Download',
      o: 'open',
      d: 'download',
      komp: 'Company',
      kompP: 'Search for companies',
      cont: 'Contact',
      contP: 'Search for contacts',
      products: 'Order',
      documents: 'Documents',
      client: 'Client',
      arch: 'Order has been successfully archived',
      logoLinkText: 'Works for',
      makePublic: 'Make an order public',
      followPublic: 'Go to the public order',
      change: 'Editing an order by a client',
      search: 'Product Search',
      password: 'Password',
      confirmpassword: 'Confirm password',
      poprofile: 'Profile',
      logout: 'Log out',
      pay: 'Pay',
      myOrders: 'My orders',
      ordersList: 'List of orders',
      docs: 'Visibility in public order',
      login: 'Login',
      gs: 'Get started',
      siya: 'Sign in to your account',
      el: 'Enter login',
      p: 'Password',
      ep: 'Enter password',
      si: 'Sign in',
    },
    widjet: {
      placeholder: 'Adding (searching the warehouse by name or article)...',
      type: 'Type :',
      ysl: 'Service',
      tow: 'Product',
      nds: 'VAT :',
      nds2: 'VAT is included in the price :',
      free: 'Free for reserve:',
      add: 'Add to order',
      addF: 'Add a filter',
      sk: ': in stock',
      res: ': in reserve',
      nds3: ': with VAT',
      nds4: ': VAT is included in the price',
      nds5: ': VAT %',
      av: 'Available in stock',
      pos: 'Entrance',
      cenT: 'Price type',
      cen: 'Price',
      sk2: 'Warehouse',
      col: 'Quantity',
      del: 'Delete position',
      del2: 'Delete',
      notAvailablePO: 'Not available in public orders'
    },
    import: {
      header: 'Import settings',
      max: 'Maximum number of products in the file: {count}',
      inf: 'To not save the template, leave the field empty',
      sr: 'Compare field',
      dob: 'Add new values',
      im: 'Import',
      bes: 'Without template',
      new: 'New template',
      noimp: 'Do not import field',
      notifH: 'Task added',
      notifT: 'Import goods',
      add: 'Add only',
      update: 'Update only',
      add_update: 'Add and update',
    },
    NotValidToken: {
      at: 'Attention!',
      at2: 'The login to the GoSklad from this account is fixed.',
      at3: 'Make sure that only one user uses this account.',
    },
    ToManyAccounts: {
      at: 'Attention!',
      at2: 'The number of users exceeded.',
    },
    Edit: {
      zn: 'Value',
      iz: 'Change the price of this product in open transactions?',
      val: 'Currency',
      nds1: 'With VAT',
      nds2: 'VAT can be changed%',
      nds3: 'VAT is included in the price',
      nds4: 'VAT value',
      ns: 'In stock',
      vr: 'In reserve',
      tagBinding: 'Binding Tag'
    },
    Errors: {
      at: 'Attention!',
      at2: 'The subscription period of the GoSklad service has come to an end. For an extension, contact the sales department:',
    },
    search: {
      orders: 'Orders',
      products: 'Products / Services',
      contacts: 'Contacts',
      companies: 'Companies',
    },
    tarifs: {
      buyBtn: 'Purchase',
      buyBtn2: 'Connect | Request an invoice | Purchased',
      price: '0 | {price}$',
      freePriceSmall: 'one-time',
      pos: 'positions',
      zak: 'orders',
      pol: '{count} user',
      pos2: '{count} positions',
      kontikomp: '{count} contacts and companies',
      noApi: 'No APIs and integrations',
      ord: '{count} orders /month',
      g: 'year',
      d: '14 days',
      imp: 'import / export',
      evry: 'for each user',
      users: '{count} users',
      exp: 'Until {date}',
      notCountry: "You haven't selected a country",
    },
    filters: {
      from: 'from:',
      to: 'to:',
    },
    removeCat: {
      header: 'Do you confirm the deletion of the category?',
      text: 'If a category is deleted, the properties of the products of this category will be moved to the root category',
      del: 'Delete',
    },
    Tips: {
      EditImg: {
        header:
          'Find the image on the internet or upload it to the cloud storage.',
        GDHeader: 'For Google Drive:',
        GDStep1: '1. Log in to your Google account and go to the Disk service.',
        GDStep2:
          '2. Create a folder by clicking the "Create" button and configure sharing by changing the access level to the folder to "Everyone on the Internet" or "Everyone who has a link".',
        GDStep3: '3. Add the files that need direct links to this folder.',
        GDStep4:
          '4. Select the desired file by highlighting it, and get a link for distribution by clicking on the "Make shared" icon on the top panel or "Share" in the dialog box when uploading.',
        GDStep5:
          'The links will look like: https://docs.google.com/file/d/здесь Your file ID/edit?usp=sharing',
        GDStep6:
          'Carefully copy the ID and add the following construction to the end: https://drive.google.com/uc ?export=download&confirm=no_antivirus&id=here is the ID of your file.',
        GDStep7: '5. Insert the link in the product field.',
        EHeader: 'For images from the Internet:',
        EStep1: '1. Open the image.',
        EStep2: '2. Click RMB and select "Copy Image URL".',
        EStep3: '3. Insert the link in the product field.',
      },
    },
    statusesForAmo: {
      opened: 'Open',
      reserved: 'In reserve',
      success: 'Successful',
      unsuccessful: 'Cancelled',
      deleted: 'Deleted',
    },
  },
  ru: {
    global: {
      yes: 'Да',
      no: 'Нет',
      back: 'Назад',
      сancel: 'Отмена',
      сancel2: 'Отменить',
      confirm: 'Подтвердить',
      apply: 'Принять',
      add: 'Добавить',
      from: 'из',
      save: 'Сохранить',
      send: 'Отправить',
      somethingWrong: 'Что-то пошло не так...',
      notSelected: 'Не выбрано',
      ok: 'ок',
      nothingFound: 'Ничего не найдено',
      error: 'Ошибка',
      all: 'Все',
      clear: 'Очистить',
      apply2: 'Применить',
      drop: 'Сбросить',
      currency: ' р.',
      enter: 'введите кол-во',
    },
    message: {
      OrdersInPipeline: 'В этой воронке остались заказы',
      tasks: 'Нет задач в процессе',
      notInst: 'Склад не установен',
    },
    countries: {
      Abkhazia: 'Абхазия',
      Australia: 'Австралия',
      Austria: 'Австрия',
      Azerbaijan: 'Азербайджан',
      'Aland Islands': 'Аландские острова',
      Albania: 'Албания',
      Algeria: 'Алжир',
      Anguilla: 'Ангилья',
      Angola: 'Ангола',
      Andorra: 'Андорра',
      Argentina: 'Аргентина',
      Armenia: 'Армения',
      Aruba: 'Аруба',
      Afghanistan: 'Афганистан',
      Bahamas: 'Багамские острова',
      Bangladesh: 'Бангладеш',
      Barbados: 'Барбадос',
      Bahrain: 'Бахрейн',
      Belarus: 'Беларусь',
      Belize: 'Белиз',
      Belgium: 'Бельгия',
      Benin: 'Бенин',
      Bulgaria: 'Болгария',
      Bolivia: 'Боливия',
      'Bosnia & Herzegovina': 'Босния и Герцеговина',
      Botswana: 'Ботсвана',
      Brazil: 'Бразилия',
      'Brunei Darussalam': 'Бруней',
      Burundi: 'Бурунди',
      Bhutan: 'Бутан',
      'Vatican City': 'Ватикан',
      'United Kingdom': 'Великобритания',
      Hungary: 'Венгрия',
      Venezuela: 'Венесуэла',
      'Timor, East': 'Восточный Тимор',
      'Viet Nam': 'Вьетнам',
      Gabon: 'Габон',
      Haiti: 'Гаити',
      Gambia: 'Гамбия',
      Ghana: 'Гана',
      Guadeloupe: 'Гваделупа',
      Guatemala: 'Гватемала',
      Guinea: 'Гвинея',
      'Guinea-Bissau': 'Гвинея-Бисау',
      Germany: 'Германия',
      Gibraltar: 'Гибралтар',
      'Hong Kong': 'Гонконг',
      Honduras: 'Гондурас',
      Grenada: 'Гренада',
      Greenland: 'Гренландия',
      Greece: 'Греция',
      Georgia: 'Грузия',
      Guam: 'Гуам',
      Denmark: 'Дания',
      Dominica: 'Доминика',
      'Dominican Republic': 'Доминиканская Республика',
      Egypt: 'Египет',
      Zambia: 'Замбия',
      'Western Sahara': 'Западная Сахара',
      Zimbabwe: 'Зимбабве',
      Israel: 'Израиль',
      India: 'Индия',
      Indonesia: 'Индонезия',
      Jordan: 'Иордания',
      Iraq: 'Ирак',
      Iran: 'Иран',
      Ireland: 'Ирландия',
      Iceland: 'Исландия',
      Spain: 'Испания',
      Italy: 'Италия',
      Yemen: 'Йемен',
      Kazakhstan: 'Казахстан',
      Cambodia: 'Камбоджа',
      Cameroon: 'Камерун',
      Canada: 'Канада',
      Qatar: 'Катар',
      Kenya: 'Кения',
      Cyprus: 'Кипр',
      Kyrgyzstan: 'Киргизия',
      Kiribati: 'Кирибати',
      China: 'Китай',
      Colombia: 'Колумбия',
      'Korea, DPR': 'Корея Северная',
      Korea: 'Корея Южная',
      'Costa Rica': 'Коста-Рика',
      'Cote dIvoire': "Кот-д'Ивуар",
      Cuba: 'Куба',
      Kuwait: 'Кувейт',
      'Lao PDR': 'Лаос',
      Latvia: 'Латвия',
      Lesotho: 'Лесото',
      Liberia: 'Либерия',
      Lebanon: 'Ливан',
      'Libyan Arab Jamahiriya': 'Ливия',
      Lithuania: 'Литва',
      Liechtenstein: 'Лихтенштейн',
      Luxembourg: 'Люксембург',
      Mauritius: 'Маврикий',
      Mauritania: 'Мавритания',
      Madagascar: 'Мадагаскар',
      Macedonia: 'Македония',
      Malawi: 'Малави',
      Malaysia: 'Малайзия',
      Mali: 'Мали',
      Maldives: 'Мальдивы',
      Malta: 'Мальта',
      Morocco: 'Марокко',
      Mexico: 'Мексика',
      Mozambique: 'Мозамбик',
      Moldova: 'Молдавия',
      Monaco: 'Монако',
      Mongolia: 'Монголия',
      Namibia: 'Намибия',
      Nepal: 'Непал',
      Niger: 'Нигер',
      Nigeria: 'Нигерия',
      Netherlands: 'Нидерланды',
      Nicaragua: 'Никарагуа',
      'New Zealand': 'Новая Зеландия',
      Norway: 'Норвегия',
      'United Arab Emirates': 'ОАЭ',
      Oman: 'Оман',
      Pakistan: 'Пакистан',
      Panama: 'Панама',
      Paraguay: 'Парагвай',
      Peru: 'Перу',
      Poland: 'Польша',
      Portugal: 'Португалия',
      Russia: 'Россия',
      Romania: 'Румыния',
      'San Marino': 'Сан-Марино',
      'Saudi Arabia': 'Саудовская Аравия',
      Senegal: 'Сенегал',
      Serbia: 'Сербия',
      Singapore: 'Сингапур',
      'Syrian Arab Republic': 'Сирия',
      Slovakia: 'Словакия',
      Slovenia: 'Словения',
      Somalia: 'Сомали',
      Sudan: 'Судан',
      USA: 'США',
      Tajikistan: 'Таджикистан',
      Thailand: 'Таиланд',
      Tanzania: 'Танзания',
      Togo: 'Того',
      Tunisia: 'Тунис',
      Turkmenistan: 'Туркменистан',
      Turkey: 'Турция',
      Uganda: 'Уганда',
      Uzbekistan: 'Узбекистан',
      Ukraine: 'Украина',
      Uruguay: 'Уругвай',
      Micronesia: 'Федеративные Штаты Микронезии',
      Fiji: 'Фиджи',
      Philippines: 'Филиппины',
      Finland: 'Финляндия',
      France: 'Франция',
      Croatia: 'Хорватия',
      Chad: 'Чад',
      Montenegro: 'Черногория',
      'Czech Republic': 'Чехия',
      Chile: 'Чили',
      Switzerland: 'Швейцария',
      Sweden: 'Швеция',
      'Sri Lanka': 'Шри-Ланка',
      Ecuador: 'Эквадор',
      Eritrea: 'Эритрея',
      Estonia: 'Эстония',
      Ethiopia: 'Эфиопия',
      'South Africa': 'ЮАР',
      Jamaica: 'Ямайка',
      Japan: 'Япония',
    },
    NavBar: {
      orders: 'Заказы',
      documents: 'Документы',
      remnants: 'Остатки',
      analytics: 'Аналитика',
      archive: 'Архив',
      clients: 'Клиенты',
      deals: 'Сделки',
      objects: 'Объекты',
    },
    HomeMenu: {
      selectLengPlaceholder: 'Выбор языка',
      err: 'Ошибка',
      tasks: {
        notask: 'Нет задач в процессе',
        title: 'Список задач',
      },
      import: {
        title: 'Импорт товаров',
        btnPlaceholder: 'Загрузить файл',
      },
      sync: {
        title: 'Синхронизация товаров',
        amo_go: 'Синхронизировать товары amoCRM -> GoСклад',
        go_amo: 'Синхронизировать товары GoСклад -> amoCRM',
        oneC: 'Синхронизировать остатки с 1C',
      },
      settings: {
        title: 'Настройки',
        general: 'Общие настройки',
        businessProcesses: 'Бизнес-процессы',
        entities: 'Настройка сущностей',
        docs: 'Документы',
        account: 'Настройки аккаунта',
        integration: 'Интеграции',
        archive: 'Архив',
        categories: 'Категории товаров',
        properties: 'Свойства товаров',
        sync: 'Настройки синхронизации товаров',
        clients: 'Настройки полей клиентов',
      },
      user: { title: 'Настройки юзера' },
    },
    DelBtnAccept: {
      del: 'Удалить',
      confirmDel: 'Точно удалить?',
    },
    HomeMenuUser: {
      logout: 'Выйти',
    },
    AccountSettings: {
      header: 'Настройки аккаунта',
      general: {
        header: 'Общие',
        accountName: 'Имя аккаунта',
        accountCountry: 'Страна аккаунта',
        steps: 'Этапы резервирования товаров со склада',
        divide: 'Разделять права',
      },
      users: {
        header: 'Пользователи',
        addBtn: 'Добавить пользователя',
        table: {
          name: 'Имя',
          email: 'Email',
          phone: 'Телефон',
          status: 'Статус',
          statusActive: 'Включен',
          statusUnActive: 'Выключен',
          editBtn: 'Редактировать',
          display: 'Показывать',
        },
      },
      license: {
        header: 'Лицензия',
      },
      editUser: {
        header: 'Редактирование пользователя',
        activity: 'Активность',
        editing_remains: 'Редактирование товара',
        change_remains: 'Приход / Списание / Перемещение товаров',
        admin: 'Админ',
      },
      newUser: {
        header: 'Добавление пользователя',
        name: 'Имя',
        email: 'Email',
        alreadyExists: 'Такой пользователь уже существует.',
        addExistUser: 'Добавить его в аккаунт?',
        notificationHeadSuccess: '{name} приглашен в аккаунт',
        notificationHeadFail: '{name} не был приглашен в аккаунт',
        notificationTextFail: 'Что-то пошло не так...',
      },
    },
    EditUserProfile: {
      name: 'Имя',
      email: 'Email',
      phone: 'Телефон',
      change: 'Изменить',
      add: 'Добавить',
    },
    EditUserChangePassword: {
      currentPassword: 'Текущий пароль',
      newPassword: 'Новый пароль',
      confirmNewPassword: 'Подтвердите новый пароль',
      showPassword: 'Показать пароль',
      changePassword: 'Изменение пароля',
    },
    Profile: {
      header: 'Профиль',
      profile: 'Профиль',
      changePassword: 'Изменение пароля',
    },
    ProfileChangeModal: {
      header: ' Изменение {type}',
      codeText: 'Код отправленный {source} на {login}:',
      inputText: 'Введите {source}',
      notificationTextFail: 'Вы использовали слишком много попыток ввода кода',
      notificationTextSuccess: 'Успешно',
      WhatsApp: 'в WhatsApp',
      email: {
        forTitle: 'почты',
        textForField: 'новую почту',
      },
      phone: {
        forTitle: 'номера телефона',
        textForField: 'новый номер телефона',
      },
    },
    EditStuff: {
      header: 'Общие настройки',
      otherDeals: 'Разрешать добавлять товары из резерва в другие заказы',
      zeroCount: 'Разрешать добавлять в заказы товар с нулевым остатком',
      oneWh: 'Товары в заказы только из одного склада',
      manualBudjet: 'Разрешать ручное редактирование суммы заказа',
      publicOrder: {
        header: 'Публичные заказы',
      },
      stepsRes: {
        header: 'Этапы резервирования товаров со склада',
        text: 'Внимание! В рамках одной воронки этап резерирования должен быть раньше этапа списания. Если этап презервирования не выбран, то резерирование будет происходить в момент добавления товара в сделку.',
        blockRes: 'Блокировать изменения товаров в этапах резервирования',
        hopper: 'Воронка "{name}"',
        placeholder: 'Не выбрано | Выбрано: {count} | Выбрано: {count}',
      },
      stepsWO: {
        header: 'Этапы списания товаров со склада',
        text: ' Внимание! В рамках одной воронки этап списания должен быть позже этапа резерирования.',
        hopper: 'Воронка "{name}"',
      },
      dealBinding: {
        header: 'Привязка полей сделок',
        budget: 'Не заполнять бюджет заказов',
        field: 'Поле "{name}"',
        number: '(число)',
      },
    },
    BusinessProcessesSettings: {
      title: 'Бизнес-процессы',
      tags: 'Теги',
      scenarios: 'Сценарии',
      addTag: 'Добавить',
      nothingFound: 'Ничего не найдено...',
      showAllProductsToAllCustomers: 'Показывать все товары всем клиентам',
      showTaggedProductsToCustomersOnlyWithMatchingTags: 'Показывать клиентам с тегами товары только с совпадением тегов',
      showCustomersProductsWithMatchingTagsOrWithoutTags: 'Показывать клиентам товары с совпадением тегов/без тегов',
      selectedScenario: 'Выбранный сценарий'
    },
    SettingEntities: {
      header: 'Настройки сущностей',
      statBtn: 'Статусы',
      tabs: {
        orders: 'Заказы',
        company: 'Компании',
        contacts: 'Контакты',
        products: 'Товары',
      },
      table: {
        field: 'Поле',
        type: 'Тип',
        visible: 'Видимость в заказе',
        edit: 'Редактирование в заказе',
        PubOrdVisible: 'Видимость в публичном заказе',
        PubOrdEdit: 'Редактирование в публичном заказе',
        headerDeal: 'Заголовок товара в заказе',
        double: 'Дублировать в новые партии',
        bind: 'Привязка к категориям',
        btnSaveAll: 'Сохранить все поля',
        titCat: 'Выберите категории',
      },
      fieldsProp: {
        btnCats: 'Категории',
        selectCat: 'Выбор подкатегории',
      },
      entCats: {
        header: 'Категории товаров',
        titleAdd: 'Добавлние новой подкатегории',
        titleChangeName: 'Изменение названия категории',
        titleSave: 'Сохраняет название категории',
        titleChange: 'Возвращает изначальное название',
        url: 'Url картинки',
        del: 'Удаление категории',
        add: 'Добавлние новой подкатегории',
      },
      entStats: {
        header: 'Настройка статусов',
        name: 'Название',
        stat: 'Статус',
        titRes: 'Резервация',
        titWO: 'Списание',
        newStatName: 'Статус {count}',
        new: 'новый статус',
      },
    },
    DocumentSetting: {
      templAdd: 'Добавление шаблона документов',
      templChange: 'Редактирование шаблона документов',
      header: 'Настройки документов',
      googleAuth: 'Вы авторизированы в сервисе Google Drive как "{name}".',
      exit: 'Выйти',
      enter: 'Войти',
      googleNotAuth: ' Вы не авторизированы в сервисе Google Drive',
      folder: 'Папка с документами',
      templFields: 'Поля шаблонов',
      templUpdate: 'Обновить поля шаблонов',
      templLink: 'Привязка полей документов',
      docField: 'Поле "Документы"',
      contOrder: 'Поряд имен контактов',
      hooks: 'Хуки',
      hookDown: 'Хук при загрузке документа в сделку',
      hookGen: 'Хук при генерации документа в сделке',
      templDocs: 'Шаблоны документов',
      templDocsText:
        'Файлы шаблонов должны быть доступны для чтения по ссылке. Google ID файла это часть ссылки на файл Пример ссылки: https://docs.google.com/spreadsheets/d/1XdXdEMtUFa8V__UK234432Dpx5-CeI/edit#gid=0, где Google ID файла: 1XdXdEMtUFa8V__UK234432Dpx5-CeI Вместо Google ID файла допускается ввод полного адреса',
      alert: 'Text copied successfully',
      alert2: 'Text not copied automatically',
      alert3: 'Try manually',
      titles: {
        name: 'Название',
        GID: 'Google ID файла',
        temp: 'Шаблон',
        typeTemp: 'Тип шаблона',
        typeDown: 'Тип для скачивания',
        POVisible: 'Видимость в публичном заказе',
      },
      autoGen: 'Авторизация Google',
      addNew: {
        name: 'Название:',
        GID: 'Google ID файла:',
        stepGen: 'Этап авто-генерации:',
        url: 'Url последнего документа:',
        templType: 'Тип шаблона:',
        downType: 'Тип для скачивания:',
        orient: {
          header: 'Ориентация',
          kn: 'Книжная',
          alb: 'Альбомная',
        },
        is: 'Исходный',
      },
      fields: {
        header: 'Поля для шаблонов документов',
      },
      docRow: {
        templ: 'Шаблон',
      },
    },
    Integrations: {
      header: 'Интеграции',
      oneCText:
        'Для интеграции с 1С-Генезис необходимо установить виджет «Генезис: 1С интеграция»',
      btnSettings: 'Настройки',
      btnInstalled: 'Установлено',
      btnInstall: 'Установить',
      free: 'Бесплатное дополнение',
      amoError: 'Для установки необходимо быть пользователем платформы amoCRM',
    },
    Archive: {
      count: 'Найдено: {count}',
      unArchive: 'Разархивирование товара',
      tabs: {
        products: 'Товары / Услуги',
        companies: 'Компании',
        contacts: 'Контакты',
        orders: 'Заказы',
      },
    },
    Genezis: {
      btnSet: 'Настройки Интеграции с 1С-Генезис',
      btnSet2: 'Настройки «Генезис: 1С интеграция»',
      gActive: 'Genezis активирован.',
      disable: 'Выключить',
      dDisable: 'Genezis не активирован.',
      active: 'Включить',
      fields: {
        comp: 'Привязка полей компании',
        compName: 'Название',
        compInn: 'Инн',
        compKpp: 'КПП',
        compOgrn: 'ОГРН',
        acc: 'Привязка полей аккаунта',
        accName: 'Название',
        accSch: 'Корреспондентский счет',
        accBik: 'Бик банка',
        accRas: 'Расчетный счет',
      },
      field: 'Поле "{name}"',
    },
    Auth: {
      header: 'Авторизация',
      tgPh: 'Логин в Telegram (login)',
      passwordPh: 'Пароль от GoSklad',
      restore: 'Восстановить пароль',
      error: 'Неверный логин или пароль',
      btnAuth: 'Авторизоваться',
      drop: 'Сброс пароля',
      reg: 'Регистрация',
      name: 'Имя',
      sendCode: 'Отправили код на :',
      notificationTextPassword: 'Мы выслали вам новый пароль на: {source}',
      notificationTextCode: 'Мы выслали вам код подтверждения на: {source}',
      notificationTextSuccess:
        'Вы зарегистрированы смотрите пароль на: {source}',
      notificationTextTryCodeFail:
        'Вы использовали слишком много попыток ввода кода',
    },
    Amo: {
      header: 'Настройки интеграции с amoCRM',
      office: 'Кабинет amoCRM:',
      tabs: {
        general: 'Общие настройки',
        sync: 'Настройки синхронизации товаров',
        docs: 'Настройки документов',
      },
      GenSett: {
        stepResH: 'Этапы резервирования товаров со склада',
        stepResT:
          'Внимание! В рамках одной воронки этап резерирования должен быть раньше этапа списания. Если этап презервирования не выбран, то резерирование будет происходить в момент добавления товара в сделку.',
        block: 'Блокировать изменения товаров в этапах резервирования',
        hopper: 'Воронка "{name}"',
        stepWOH: 'Этапы списания товаров со склада',
        stepWOT:
          'Внимание! В рамках одной воронки этап списания должен быть позже этапа резерирования.',
        linkFH: 'Привязка полей сделок',
        linkFBudjet: 'Не заполнять бюджет сделки',
        field: 'Поле "{name}"',
        number: '(число)',
        leadsDeals: {
          field_products: 'Товары',
          field_search_products: 'Поле для поиска товаров',
          field_cost_price: 'Себестоимость',
          field_profit: 'Прибыль',
          field_budget: 'Общий бюджет',
          field_nds_sum: 'НДС сумма',
        },
      },
      SyncSett: {
        header: 'Выберите список товаров из amoCRM для синхронизации',
        hide: 'Скрытие штатной вкладки amoCRM с товарами. В штатной вкладке с товарами так же можно прикреплять товары к сделкам, но количество товара на GoСклад учитываться не будет.',
        corrH:
          'Настройка соответствий полей amoCRM-товаров и свойств товаров на GoСклад',
        corrT:
          'Указание полей сравнения нужно для того, чтобы по этим полям искать дубли товаров в процессе синхронизации списков товаров amoCRM и GoСклад, которые были добавлены вручную. Если не будет указано ни одного поля, товары будут добавляться без поиска дублей. Если настраивается привязка групп товаров из amoCRM и категорий товаров в GoSklad нужно убедиться, что список групп соответствует списку категорий.',
        amoName: 'Имя поля amoCRM-товара',
        goName: 'Имя поля товара на GoСклад',
        comparison: {
          header: 'Сравнение',
          name: 'по названию',
          art: 'по названию и артикулу',
          batch: 'по названию, артикулу и партии',
        },
        update: 'Обновить поля',
        goAmo: 'GoСклад -> amoCRM',
        amoGo: 'amoCRM -> GoСклад',
        autoSync: 'Автосинхронизация товаров',
        autoSyncOrder: 'Автосинхронизация списка товаров в сделке',
      },
    },
    kommo: {
      header: 'Настройки интеграции с Kommo',
      office: 'Кабинет Kommo:',
      tabs: {
        general: 'Общие настройки',
        sync: 'Настройки синхронизации товаров',
        docs: 'Настройки документов',
      },
      GenSett: {
        stepResH: 'Этапы резервирования товаров со склада',
        stepResT:
          'Внимание! В рамках одной воронки этап резерирования должен быть раньше этапа списания. Если этап презервирования не выбран, то резерирование будет происходить в момент добавления товара в сделку.',
        block: 'Блокировать изменения товаров в этапах резервирования',
        hopper: 'Воронка "{name}"',
        stepWOH: 'Этапы списания товаров со склада',
        stepWOT:
          'Внимание! В рамках одной воронки этап списания должен быть позже этапа резерирования.',
        linkFH: 'Привязка полей сделок',
        linkFBudjet: 'Не заполнять бюджет сделки',
        field: 'Поле "{name}"',
        number: '(число)',
        leadsDeals: {
          field_products: 'Товары',
          field_search_products: 'Поле для поиска товаров',
          field_cost_price: 'Себестоимость',
          field_profit: 'Прибыль',
          field_budget: 'Общий бюджет',
          field_nds_sum: 'НДС сумма',
        },
      },
      SyncSett: {
        header: 'Выберите список товаров из Kommo для синхронизации',
        hide: 'Скрытие штатной вкладки Kommo с товарами. В штатной вкладке с товарами так же можно прикреплять товары к сделкам, но количество товара на GoСклад учитываться не будет.',
        corrH:
          'Настройка соответствий полей Kommo-товаров и свойств товаров на GoСклад',
        corrT:
          'Указание полей сравнения нужно для того, чтобы по этим полям искать дубли товаров в процессе синхронизации списков товаров amoCRM и GoСклад, которые были добавлены вручную. Если не будет указано ни одного поля, товары будут добавляться без поиска дублей. Если настраивается привязка групп товаров из Kommo и категорий товаров в GoSklad нужно убедиться, что список групп соответствует списку категорий.',
        amoName: 'Имя поля Kommo-товара',
        goName: 'Имя поля товара на GoСклад',
        comparison: {
          header: 'Сравнение',
          name: 'по названию',
          art: 'по названию и артикулу',
          batch: 'по названию, артикулу и партии',
        },
        update: 'Обновить поля',
        goAmo: 'GoСклад -> Kommo',
        amoGo: 'Kommo -> GoСклад',
        autoSync: 'Автосинхронизация товаров',
        autoSyncOrder: 'Автосинхронизация списка товаров в сделке',
      },
    },
    DocumentsTitles: {
      status_id: {
        name: 'Статус',
        placeholder: 'Статус документа',
      },
      template_name: {
        name: 'Название документа',
        placeholder: 'Название документа',
      },
      lead_name: {
        name: 'Название заказа',
        placeholder: 'Название заказа',
      },
      company_name: {
        name: 'Компания',
        placeholder: 'Компания',
      },
      contact_name: {
        name: 'Контакт',
        placeholder: 'Контакт',
      },
      user_name: {
        name: 'Кто сформировал',
        placeholder: 'Кто сформировал',
      },
      created_at: {
        name: 'Когда сформировал',
        placeholder: 'Когда сформировал',
      },
    },
    Analytics: {
      positions: 'Позиции компании "{company}"',
      group: 'Группировать по: ',
      reportTypes: {
        customers: 'Отчет по клиентам',
        sales: 'Отчет по продажам',
        movement: 'Движение товаров',
      },
      titles: {
        customers: {
          company: 'Компания',
          contact: 'Контакт',
          leads: 'Сделки',
          sum: 'Оборот',
          prib: 'Прибыль',
          otv: 'Ответственные',
          poz: 'Позиции',
        },
        sales: {
          name: 'Название',
          count: 'Кол-во',
          sum: 'Оборот',
          cost_sum: 'Себестоимость',
          prib: 'Прибыль',
          leads: 'Сделки',
          poz: 'Позиции',
        },
        movement: {
          event_date: 'Дата события',
          event_type: 'Тип события',
          article_number: 'Артикул',
          title: 'Название',
          party: 'Партия',
          quantity: 'Колличество',
          cost_price: 'Себестоимость',
          sum: 'Сумма',
          initial_warehouse: 'Начальный склад',
          final_warehouse: 'Итоговый склад',
          user: 'Пользователь',
          comment: 'Комментарий',
        },
      },
      groupComp: 'Компаниям',
      groupContact: 'Контактам',
      general: 'Общее',
      subTitles: {
        name: 'Название',
        count: 'Кол-во',
        sum: 'Оборот',
        prib: 'Прибыль',
      },
      reportFilters: {
        createTempl: 'Создать шаблон',
        system: 'Система',
        fields: {
          product: 'Артикулы/Названия',
          responsible: 'Ответственные',
          pipeline: 'Воронки',
          company: 'Компании',
          contact: 'Контакты',
          users: 'Пользователи',
          event_type: 'Тип события',
          product_batch: 'Партия',
          initial_warehouse: 'Начальный склад	',
          final_warehouse: 'Итоговый склад	',
          quantity: 'Колличество',
        },
      },
      createModal: {
        new: 'Новый отчет',
        name: 'Название отчета',
        placeholder: 'По продажам',
        by: 'По ',
      },
      reportGrid: {
        show: 'Показать',
        hide: 'Скрыть',
      },
      chartsFilters: {
        type: 'Тип отчета',
        days: 'По дням',
        months: 'По месяцам',
        years: 'По годам',
        total: 'Всего продаж',
        customers: 'По клиентам',
        sales: 'По продажам',
      },
      systemsForReports: {
        entities: {
          placeholder: 'Сущность',
          trade: 'Сделка',
          contact: 'Контакт',
          company: 'Компания',
        },
        data: {
          placeholder: 'Данные',
          event: 'События',
          data: 'Данные',
        },
        trade: {
          placeholder: 'Сделки',
          1: 'Создание',
          2: 'Смена этапа',
          3: 'смена ответственного',
          4: 'добавление примечания',
          5: 'изменение поля',
          6: 'привязка компании',
          7: 'привязка контакта',
        },
        trade2: {
          placeholder: 'Сделки',
          1: 'связанные контакты',
          2: 'связанная компания',
          3: 'примечания',
          4: 'дата создания',
          5: 'дата закрытия (успешно, не успешно этапы',
          6: 'теги',
          7: 'список задач',
        },
        contact: {
          placeholder: 'Контакты',
          1: 'создание (дата)',
          2: 'звонок',
          3: 'новый чат (сообщение) - список чатов: вк, фб, инста, вотсап, телега, прочие',
          4: 'отправлено смс',
          5: 'отправлено письмо / получено письмо',
          6: 'смена ответственного',
          7: 'изменение поля / тега',
          8: 'привязка к сделке',
          9: 'привязка к контакту',
        },
        contact4: {
          placeholder: 'Контакты',
          1: 'ответственный',
          2: 'телефон',
          3: 'почта',
          4: 'связанные сделки',
          5: 'связанная компания',
          6: 'должность',
          7: 'пользовательские поля (id)',
          8: 'примечания',
          9: 'теги',
          10: 'список задач',
        },
        company: {
          placeholder: 'Компании',
          1: 'привязка к сделке)',
          2: 'привязка к контакту',
          3: 'создание (дата)',
          4: 'звонок',
          5: 'отправлено письмо / получено письмо',
          6: 'изменение поля / тега',
        },
        company6: {
          placeholder: 'Компании',
          1: 'ответственный',
          2: 'телефон',
          3: 'почта',
          4: 'сайт',
          5: 'связанные контакты',
          6: 'связанные сделки',
          7: 'пользовательские поля (id)',
          8: 'теги',
          9: 'примечания',
          10: 'список задач',
        },
        task: {
          placeholder: 'Задачи',
          1: 'создание задачи',
          2: 'изменение задачи (срок)',
          3: 'изменение задачи (тип)',
          4: 'изменение задачи (содержимое)',
          5: 'закрытие задачи (с результатом или без ???)',
        },
        task8: {
          placeholder: 'Задачи',
          1: 'ответственный',
          2: 'дата создания',
          3: 'дата изменения',
          4: 'дата завершения',
          5: 'тип задачи',
          6: 'содержимое задачи',
          7: 'результат выполнения',
          8: 'связаные компании / контакты / сделки',
        },
        date: {
          placeholder: 'Интервал дат',
        },
        vortexAndPhase: {
          placeholder: 'Воронка и этап',
          all: 'Все',
          1: 'Этап 1',
          2: 'Этап 2',
          3: 'Этап 3',
          4: 'Этап 4',
          5: 'Воронка 1',
          6: 'Воронка 2',
          7: 'Воронка 3',
          8: 'Воронка 4',
        },
        responsible: {
          placeholder: 'Отвественный',
          1: 'Отвественный 1',
          2: 'Отвественный 2',
          3: 'Отвественный 3',
          4: 'Отвественный 4',
          5: 'Отвественный 5',
        },
        tags: {
          placeholder: 'Теги',
          1: 'Тег 1',
          2: 'Тег 2',
          3: 'Тег 3',
          4: 'Тег 4',
          5: 'Тег 5',
        },
        initiator: {
          placeholder: 'Инициатор события',
          1: 'Инициатор 1',
          2: 'Инициатор 2',
          3: 'Инициатор 3',
          4: 'Инициатор 4',
          5: 'Инициатор 5',
        },
        before: {
          placeholder: 'До',
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
        },
        after: {
          placeholder: 'После',
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
        },
        taskStatus: {
          placeholder: 'Статус задачи',
          0: 'Открытый',
          1: 'Успешный',
          2: 'Отменен',
          3: 'Удален',
        },
        taskType: {
          placeholder: 'Тип задачи',
          0: 'Открытый',
          1: 'Успешный',
          2: 'Отменен',
          3: 'Удален',
        },
        messageType: {
          placeholder: 'Тип сообщения',
          1: 'Входящий',
          2: 'Исходящий',
        },
      },
    },
    Clients: {
      tabs: {
        contact: 'Контакты',
        company: 'Компании',
      },
      exitConfirm: 'Вы уверены что хотите выйти без сохранения?',
      exit: 'Выйти',
      Edit: {
        archivate: 'Архивировать',
        orders: 'История заказов',
        emptyF: 'Пустое поле',
        invalidEmail: 'Почта невалидна',
        lenght: 'Минимум 3 символа',
        link: 'Привязка',
        rating: 'Рейтинг',
        follow: 'Перейти',
        delete: 'Удалить',
        tagBinding: 'Привязка тегов'
      },
    },
    ostatki: {
      inWH: 'Только положительные остатки',
      newPoz: 'Добавить',
      syncT: 'Обновить список',
      settingsT: 'Настройка таблицы',
      allwhs: 'Все склады',
      changePrice: 'Редактирование цены',
      changePoz: 'Редактирование товара',
      red: 'Редактирование {name}',
      arch: 'Архивировать',
      type: 'Тип:',
      cat: 'Категория:',
      tov: 'Товар',
      ysl: 'Услуга',
      taskH: 'Добавлена задача',
      taskH2: 'Экспорт отчета в xlsx',
      taskT: 'Экспорт списка товаров в xlsx',
      pn: 'Изменение цены "{name}"',
      price: 'Цена:',
      price2: 'Изменить цену этого товара в открытых сделках?',
      sortBy: 'Сортировать по:',
      att: ' Внимание! Идет загурзка данных аккаунта. Это может занять несколько минут',
      att2: 'Внимание, обнаружены данные вашего аккаунта предыдущей версии склада. Для загрузки данных нажмите на кнопку ЗАГРУЗИТЬ. Загрузка может занять некоторое время.',
      d: 'Загрузить',
      selWh: 'Выберите склад',
      ysls: 'Услуги',
      menu: 'Меню',
      btn1: 'Добавить',
      btn2: 'Списать',
      btn3: 'Перемещение',
      btn4: 'Архивировать',
      f: 'Фильтр',
      spis: 'Списание товаров',
      art: 'Артикул',
      name: 'Название',
      batch: '№ партии',
      wh: 'Склад',
      wh1: 'Склад (откуда)',
      wh2: 'Склад (куда)',
      count: 'Кол-во',
      reason: 'Комментарий',
      per: 'Перенос товаров',
      new: 'Новая',
      osn: 'Основной склад',
      allObj: 'Все объекты',
      tip: 'Тип',
      cat2: 'Категория',
      op: 'Описание',
      nds1: 'НДС',
      nds2: 'включен в цену',
      nds3: 'можно менять %',
      nds4: '% НДС',
      fieldE: 'Пустое поле',
      doubleBatch: 'Существующая партия',
      add: 'Добавление товаров',
      ed: 'Единицы измерений',
      seb: 'Себестоимость',
      price3: 'Цена',
      cost_sum: 'Сумма закупки',
      sum: 'Сумма',
      ndsT: 'НДС',
      nt: 'Настройка таблицы',
      ex: 'Экспорт таблицы в xlsx',
      error: 'Ошибка',
      total_count: 'Всего позиций:',
      total_cost_sum: 'Сумма закупки:',
      total_sum: 'Сумма продажи:',
    },
    orders: {
      ors: 'Заказы',
      hfs: 'Скрыть финальные этапы',
      cre: 'Создать заказ',
      exp: 'Экспорт',
      up: 'Обновить список',
      sd: 'Заказов:',
      woz: 'Возврат',
      ser: 'Поиск и фильтр',
      po: 'Публичный заказ',
      link: 'Привязать',
      unlink: 'Отвязать',
      lid: 'ID Сделки',
    },
    tableSet: {
      name: 'Настройка таблицы',
      sm: 'Перетаскивайте колонки для изменения очередности',
    },
    newOrder: {
      newOrderName: '#{id}',
      a: 'Выбор шаблона',
      saving: 'Сохраняем',
      confirmOrder: 'Подтвердить заказ',
      spis: 'Список позиций',
      dan: 'Данные заказа',
      name: 'Название',
      stat: 'Статус',
      soz: 'Создан:',
      izm: 'Изменен:',
      sum: 'Сумма заказа:',
      cp: 'Себестоимость:',
      prib: 'Прибыль:',
      sotr: 'Сотрудник:',
      auth: 'Авторизуйтесь в Google для работы с документами.',
      gen: 'Генерация',
      gen2: 'Сгенерировать',
      z: 'Загружаем',
      z2: 'Загрузить',
      o: 'открыть',
      d: 'скачать',
      komp: 'Компания',
      kompP: 'Поиск компаний',
      cont: 'Контакт',
      contP: 'Поиск контактов',
      products: 'Заказ',
      documents: 'Документы',
      client: 'Клиент',
      arch: 'Заказ успешно архивирован',
      logoLinkText: 'Работает на',
      makePublic: 'Сделать заказ публичным',
      followPublic: 'Перейти к публичному заказу',
      change: 'Редактирование заказа клиентом',
      search: 'Поиск товаров',
      password: 'Пароль',
      confirmpassword: 'Подтвердите пароль',
      poprofile: 'Профиль',
      logout: 'Выйти',
      pay: 'Оплатить',
      myOrders: 'Мои заказы',
      ordersList: 'Список заказов',
      docs: 'Видимость в публичном заказе',
      login: 'Логин',
      gs: 'Приступим',
      siya: 'Войдите в свой аккаунт',
      el: 'Введите логин',
      p: 'Пароль',
      ep: 'Введите пароль',
      si: 'Войти',
    },
    widjet: {
      placeholder: 'Добавление (поиск на складе по названию или артикулу)...',
      type: 'Тип :',
      ysl: 'Услуга',
      tow: 'Товар',
      nds: 'НДС :',
      nds2: 'НДС включен в цену :',
      free: 'Свободно для резерва:',
      add: 'Добавить в заказ',
      addF: 'Добавить фильтр',
      sk: ': на складе',
      res: ': в резерве',
      nds3: ': с НДС',
      nds4: ': НДС включен в цену',
      nds5: ': НДС %',
      av: 'Есть в наличии',
      pos: 'Поступление',
      cenT: 'Тип цены',
      cen: 'Цена',
      sk2: 'Склад',
      col: 'Количество',
      del: 'Удалить позицию',
      del2: 'Удалить',
      notAvailablePO: 'Недоступна в публичных заказах'
    },
    import: {
      header: 'Настройки импорта',
      max: 'Максимальное количество товаров в файле: {count}',
      inf: 'Чтобы не сохранять шаблон оставте поле пустым',
      sr: 'Сравнивать поле',
      dob: 'Добавлять новые значения',
      im: 'Импортировать',
      bes: 'Без шаблона',
      new: 'Новый шаблон',
      noimp: 'Не импортировать поле',
      notifH: 'Добавлена задача',
      notifT: 'Импорт товаров',
      add: 'Только добавлять',
      update: 'Только обновлять',
      add_update: 'Добавлять и обновлять',
    },
    NotValidToken: {
      at: 'Внимание!',
      at2: 'Зафиксирован вход в GoСклад c данной учетной записи.',
      at3: 'Убедитесь, что этой учетной записью пользуется только один пользователь.',
    },
    ToManyAccounts: {
      at: 'Внимание!',
      at2: 'Превышено количество пользователей.',
    },
    Edit: {
      zn: 'Значение',
      iz: 'Изменить цену этого товара в открытых сделках?',
      val: 'Валюта',
      nds1: 'С НДС',
      nds2: 'НДС можно менять %',
      nds3: 'НДС включен в цену',
      nds4: 'НДС значение',
      ns: 'На складе',
      vr: 'В резерве',
    },
    Errors: {
      at: 'Внимание!',
      at2: 'Срок подписки сервиса GoСклад подошел к концу. Для продления обратитесь в отдел продаж:',
    },
    search: {
      orders: 'Заказы',
      products: 'Товары / Услуги',
      contacts: 'Контакты',
      companies: 'Компании',
    },
    tarifs: {
      buyBtn: 'Приобрести',
      buyBtn2: 'Подключить | Запросить счет | Приобретено',
      price: '0 | {price}$',
      freePriceSmall: 'одноразово',
      pos: 'позиций',
      zak: 'заказов',
      pol: '{count} пользователь',
      pos2: '{count} позиций',
      kontikomp: '{count} контактов и компаний',
      noApi: 'Нет API и интеграций',
      ord: '{count} заказов / месяц',
      g: 'год',
      d: '14 дней',
      imp: 'импорт / экспорт',
      evry: 'за каждого пользователя',
      users: '{count} пользователей',
      exp: 'До {date}',
      notCountry: 'Вы не выбрали страну',
    },
    filters: {
      from: 'от:',
      to: 'до:',
    },
    removeCat: {
      header: 'Подтверждаете удаление категории?',
      text: 'В случае удаления категории свойства товаров этой категории будут перенесены в корневую категорию',
      del: 'Удалить',
    },
    Tips: {
      EditImg: {
        header:
          'Найдите изображение в интернете или загрузите его в облачное хранилище.',
        GDHeader: 'Для Google Drive:',
        GDStep1: '1. Зайдите в свой аккаунт Google и перейдите в сервис Диск.',
        GDStep2:
          '2. Создайте папку, нажав кнопку "Создать", и настройте совместный доступ, изменив уровень доступа к папке на "Всем в интернете" или "Всем, у кого есть ссылка".',
        GDStep3:
          '3. Добавьте файлы, для которых нужны прямые ссылки, в эту папку.',
        GDStep4:
          '4. Выберите нужный файл, выделив его, и получите ссылку для раздачи, кликнув по значку "Сделать общими" на верхней панели или "Поделиться" в диалоговом окне при загрузке.',
        GDStep5:
          'Ссылки будут иметь вид: https://docs.google.com/file/d/здесь ID вашего файла/edit?usp=sharing',
        GDStep6:
          'Аккуратно скопируйте идентификатор и добавьте в конец следующую конструкцию: https://drive.google.com/uc?export=download&confirm=no_antivirus&id=здесь ID вашего файла.',
        GDStep7: '5. Вставьте ссылку в поле товара.',
        EHeader: 'Для изображения из интернета:',
        EStep1: '1. Откройте изображение.',
        EStep2: '2. Нажмите ПКМ и выберите "Копировать URL картинки".',
        EStep3: '3. Вставьте ссылку в поле товара.',
      },
    },
    statusesForAmo: {
      opened: 'Открытый',
      reserved: 'В резерве',
      success: 'Успешный',
      unsuccessful: 'Отменен',
      deleted: 'Удален',
    },
  },
};

// const flattenObject = (obj, prefix = "") => {
//   return Object.keys(obj).reduce((result, key) => {
//     const prop = prefix ? `${prefix}_${key}` : key;
//     if (typeof obj[key] === "object" && obj[key] !== null) {
//       Object.assign(result, flattenObject(obj[key], prop));
//     } else {
//       result[prop] = obj[key];
//     }
//     return result;
//   }, {});
// };
// const res = {};
// Object.keys(messages).forEach((lang) => {
//   res[lang] = {};
//   Object.keys(messages[lang]).forEach((head) => {
//     res[lang][head] = flattenObject(messages[lang][head]);
//   });
// });
// console.log(JSON.stringify(res));
