import { instance, poinstance, useRedirectToAuth } from '@/composables/BaseURL';
import { useNotification } from './notification';
import { useLangConfiguration } from './langConfiguration';
import { isPublicOrder } from '@/components/PublicOrder';
import router from '@/router';
const { t } = useLangConfiguration();

export async function ApiReqFunc(config, isPOInstance) {
  const { redirectToErrorPage } = useRedirectToAuth();
  const { addNotification } = useNotification();

  let response = null;

  try {
    response =
      isPOInstance || isPublicOrder.value
        ? poinstance(config)
        : instance(config);
    await response;

    return response;
  }
  catch (error) {
    const { status } = error.response;

    if (status === 403) {
      redirectToErrorPage();
      return { error: error.response }
    }

    if (status === 402) {
      router.push('/many-accounts')
      return { error: error.response }
    }

    addNotification(
      3,
      t('global.error'),
      error.response.data.error || error.response.data.message || ''
    )

    return response;
  }
}
